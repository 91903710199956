import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ScrollSpyMds = ({ sections, offset = 150 }) => {
  const [activeSection, setActiveSection] = useState(sections[0]?.id || '');

  const handleScroll = () => {
    let currentSection = '';
    sections.forEach((section) => {
      const element = document.getElementById(section.id);
      if (element && element.getBoundingClientRect().top < offset + 150) {
        currentSection = section.id;
      }
    });
    setActiveSection(currentSection);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sections]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - offset - 50,
        behavior: 'smooth',
      });
    }
  };

  return (
    <nav>
      <ul className="mds-scrollspy">
        {sections.map((section) => (
          <li
            key={section.id}
            className={activeSection === section.id ? 'active' : ''}>
            <button type="button" onClick={() => scrollToSection(section.id)}>
              {section.title}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

ScrollSpyMds.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  offset: PropTypes.number,
};

ScrollSpyMds.defaultProps = {
  offset: 150,
};

export default ScrollSpyMds;
