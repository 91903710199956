/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import Layout from 'components/Layout';
// import { Link } from 'gatsby';
import '../../styles/motion-deign-system.scss';
import DesignSystemSidebar from '../../components/DesignSystemSidebar/DesignSystemSidebar';
import ScrollSpyMds from '../../components/ScrollSpyMds/ScrollSpyMds';

const sections = [
  { id: 'motion', title: 'Motion' },
  { id: 'principles', title: 'Principles' },
  { id: 'easings', title: 'Easings' },
  { id: 'duration', title: 'Duration' },
  { id: 'animatable-properties', title: 'Animatable Properties' },
  { id: 'choreography', title: 'Choreography' },
  { id: 'accessibility', title: 'Accessibility' },
];

const MotionDesignSystems = () => {
  useEffect(() => {
    const animatableProperties = document.querySelectorAll('.interative-area');

    animatableProperties.forEach((property) => {
      property.addEventListener('click', () => {
        property.classList.toggle('animate');
      });
    });
  }, []);

  return (
    <Layout>
      <section>
        <div className="design-system-main">
          <DesignSystemSidebar />

          <div className="motion-design-system-middle">
            <div className="mds-content-wrapper content-wrapper-inner">
              <div className="mds-content-single" id="motion">
                <h2 className="heading">Motion</h2>

                <p>
                  Motion is the language of change. It guides, delights, and
                  elevates experiences. Well-crafted movement transforms
                  experiences, making them intuitive, enjoyable, and memorable.
                  It&apos;s not just about seeing, it&apos;s about feeling the
                  journey.
                </p>
                <div>
                  <video
                    className="rounded-16"
                    src="/assets/video/motion-banner.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline></video>
                </div>
              </div>
              <div className="mds-content-single" id="principles">
                <h2 className="heading">Principles</h2>

                <p>
                  Motion design principles are the guiding stars for creating
                  purposeful and delightful movement. Our system is built on two
                  key fundamental ideas: focus and fun. We believe motion should
                  serve a clear purpose while adding a touch of joy to the user
                  experience.
                </p>

                <div className="flex-row">
                  <div className="col col-6">
                    <div className="motion-principle-single">
                      <div className="principle-video">
                        <video
                          className="rounded-16"
                          src="/assets/video/focused.mp4"
                          autoPlay
                          loop
                          muted
                          playsInline></video>
                      </div>
                      <div className="principle-desc">
                        <h3>Focused</h3>
                        <p>
                          Motion draws attention to what&apos;s important,
                          without creating unnecessary distraction.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col col-6">
                    <div className="motion-principle-single">
                      <div className="principle-video">
                        <video
                          className="rounded-16"
                          src="/assets/video/fun.mp4"
                          autoPlay
                          loop
                          muted
                          playsInline></video>
                      </div>
                      <div className="principle-desc">
                        <h3>Fun</h3>
                        <p>
                          Delightful motion adds personality to everyday actions
                          and reflects the brand&apos;s personality
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mds-content-single" id="easings">
                <h2 className="heading">Easings</h2>

                <p>
                  Easings breathe life into our interactions. By carefully
                  selecting and assigning relevant easing values to the
                  different UI animations in our product, we create motion that
                  feels natural and intuitive, enhancing the overall user
                  experience.
                </p>

                <div className="table-responsive">
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    className="table mds-table">
                    <thead>
                      <tr>
                        <th>Tokens</th>
                        <th>CSS</th>
                        <th>Usage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        {
                          token: '$durationInstant',
                          css: '0ms',
                          usage: 'Text change',
                        },
                        {
                          token: '$durationQuick1',
                          css: '80ms',
                          usage: 'Checkboxes, Radio Buttons, Hover',
                        },
                        {
                          token: '$durationQuick2',
                          css: '120ms',
                          usage: 'Toggles, Tabs, Chips, Color, Fade',
                        },
                        {
                          token: '$durationModerate1',
                          css: '180ms',
                          usage: 'Dropdowns, Tooltips',
                        },
                        {
                          token: '$durationModerate2',
                          css: '260ms',
                          usage: 'Modals, Toast, Dialog, Notification',
                        },
                        {
                          token: '$durationGentle1',
                          css: '320ms',
                          usage: 'Bottom Sheet, Card Expand',
                        },
                        {
                          token: '$durationGentle2',
                          css: '400ms',
                          usage: 'Advanced Animations',
                        },
                      ].map(({ token, css, usage }, index) => (
                        <tr key={index}>
                          <td>
                            <span className="mds-token">{token}</span>
                          </td>
                          <td>{css}</td>
                          <td>{usage}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="flex-row">
                  <div className="col col-4">
                    <div className="mds-easing">
                      <div className="easing-img">
                        <img src="/assets/img/decelerated-easing.svg" alt="" />
                      </div>
                      <div className="easing-desc">
                        <h3>Decelerated Easing</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col col-4">
                    <div className="mds-easing">
                      <div className="easing-img">
                        <img src="/assets/img/acclerated-easing.svg" alt="" />
                      </div>
                      <div className="easing-desc">
                        <h3>Acelerated Easing</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col col-4">
                    <div className="mds-easing">
                      <div className="easing-img">
                        <img src="/assets/img/standard-easing.svg" alt="" />
                      </div>
                      <div className="easing-desc">
                        <h3>Standard Easing</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mds-content-single" id="duration">
                <h2 className="heading">Duration</h2>

                <p>
                  Duration sets the pace of our animations. Precise timing
                  ensures smooth and natural movement, creating a harmonious
                  rhythm across the interface. Our duration values balances
                  speed and subtlety for optimal user experience.
                </p>

                <div className="table-responsive">
                  <table className="table mds-table">
                    <thead>
                      <tr>
                        <th>Tokens</th>
                        <th>CSS</th>
                        <th>Usage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[
                        {
                          token: '$durationInstant',
                          css: '0ms',
                          usage: 'Text change',
                        },
                        {
                          token: '$durationQuick1',
                          css: '80ms',
                          usage: 'Checkboxes, Radio Buttons, Hover ',
                        },
                        {
                          token: '$durationQuick2',
                          css: '120ms',
                          usage: 'Toggles, Tabs, Chips, Color, Fade',
                        },
                        {
                          token: '$durationModerate1',
                          css: '180ms',
                          usage: 'Dropdowns, Tooltips',
                        },
                        {
                          token: '$durationModerate2',
                          css: '260ms',
                          usage: 'Modals, Toast, Dialog, Notification',
                        },
                        {
                          token: '$durationGentle1',
                          css: '320ms',
                          usage: 'Bottom Sheet, Card Expand',
                        },
                        {
                          token: '$durationGentle2',
                          css: '400ms',
                          usage: 'Advanced Animations',
                        },
                      ].map(({ token, css, usage }, index) => (
                        <tr key={index}>
                          <td>
                            <span className="mds-token">{token}</span>
                          </td>
                          <td>{css}</td>
                          <td>{usage}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div>
                  <img src="/assets/img/duration-illustration.webp" alt="" />
                </div>
              </div>

              <div className="mds-content-single" id="animatable-properties">
                <h2 className="heading mb-32">Animatable Properties</h2>
                <h3 className="heading-small">Transitions</h3>
                <p>
                  Our motion system is built on a foundation of core animatable
                  properties: scale, fade, rotate, and move which shape how
                  elements come to life and interact. These core actions are the
                  foundation for every dynamic change, bringing our designs to
                  life with purpose and grace.
                </p>

                <div className="flex-row">
                  <div className="col col-6">
                    <div className="animatable-property-single">
                      <button
                        type="button"
                        className="interative-area scale-anim">
                        <div className="animated-circle"></div>
                      </button>

                      <h3>Scale</h3>
                    </div>
                  </div>
                  <div className="col col-6">
                    <div className="animatable-property-single">
                      <button
                        type="button"
                        className="interative-area fade-anim">
                        <div className="animated-rectangle"></div>
                      </button>

                      <h3>Fade</h3>
                    </div>
                  </div>
                  <div className="col col-6">
                    <div className="animatable-property-single">
                      <button
                        type="button"
                        className="interative-area rotate-anim">
                        <div className="animated-rectangle"></div>
                      </button>

                      <h3>Rotate</h3>
                    </div>
                  </div>
                  <div className="col col-6">
                    <div className="animatable-property-single">
                      <button
                        type="button"
                        className="interative-area move-anim">
                        <div className="animated-circle"></div>
                      </button>

                      <h3>Move</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mds-content-single" id="choreography">
                <h2 className="heading">Choreography</h2>

                <p>
                  Our motion system is built on a foundation of core animatable
                  properties: scale, fade, rotate, and move which shape how
                  elements come to life and interact. These core actions are the
                  foundation for every dynamic change, bringing our designs to
                  life with purpose and grace.
                </p>

                <div className="transition-patterns-main">
                  <h3 className="heading-small">Transition patterns</h3>

                  <div className="transition-patterns-wrapper">
                    <div className="transition-pattern-single">
                      <h4>Top level</h4>

                      <p>
                        When tapping a navigation bar, rail or drawer, a quick
                        fade is used to transition to a new destination.
                        <br />
                        <span className="semi-bold blue">Standard easing </span>
                        for fade of duration
                        <span className="semi-bold blue"> 100ms</span>
                      </p>

                      <div className="transition-pattern-video">
                        <video
                          src="/assets/video/top-level.mp4"
                          autoPlay
                          loop
                          muted
                          playsInline></video>
                      </div>
                    </div>
                    <div className="transition-pattern-single">
                      <h4>Enter and exit</h4>

                      <p>
                        This transition is used for entering and exiting of side
                        panels.
                        <br />
                        <span className="blue semi-bold">
                          Decelerated easing&nsbsp;
                        </span>
                        for fade of duration
                        <span className="blue semi-bold"> 400ms</span>
                        <br />
                        <span className="blue semi-bold">
                          Accelerated easing&nbsp;
                        </span>
                        for fade of duration
                        <span className="blue semi-bold"> 200ms</span>
                      </p>

                      <div className="transition-pattern-video">
                        <video
                          src="/assets/video/enter-exit.mp4"
                          autoPlay
                          loop
                          muted
                          playsInline></video>
                      </div>
                    </div>
                    <div className="transition-pattern-single">
                      <h4>Skeleton Loader</h4>

                      <p>
                        Skeleton loaders are UI abstractions that hint at where
                        content will appear once it&apos;s loaded. A pulsing
                        animation indicates indeterminant loading and the
                        content quickly fades in once it&apos;s loaded.
                      </p>

                      <div className="transition-pattern-video">
                        <video
                          src="/assets/video/skeleton-loader.mp4"
                          autoPlay
                          loop
                          muted
                          playsInline></video>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mds-content-single" id="accessibility">
                <h2 className="heading">Accessibility</h2>

                <p>
                  Motion in design can guide users and enhance the experience,
                  but it&apos;s crucial to consider accessibility. Animations
                  can trigger discomfort in users with vision issues, epilepsy,
                  or vestibular disorders. To ensure safety, keep motion
                  purposeful, clear, and avoidable through settings. This aligns
                  with our goal of inclusive design, letting everyone benefit
                  from our product.
                </p>
              </div>
            </div>
          </div>

          <div className="mds-right-bar">
            <div className="right-bar-content">
              <h3>On this page</h3>

              <ScrollSpyMds sections={sections} offset={50} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default MotionDesignSystems;
